<template>
    <div class="normal-container pt-3 pb-4 py-sm-5">
        <PreferredFacilityModal
            v-show="selectFacility"
            :disable-closing="setFacility === 'true'"
            @close="toggleModal"
        />

        <div v-if="isProfileLoaded" class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="row align-items-end">
                    <div class="col-md-auto text-center mb-4">
                        <img
                            src="@assets/images/icon-profile.png"
                            alt=""
                            width="90"
                        />
                    </div>

                    <div class="col mb-4">
                        <h2 class="text-primary">My Profile:</h2>
                    </div>

                    <div class="col-auto mb-4">
                        <router-link
                            :to="{ name: 'edit-account', hash: '#anchor' }"
                        >
                            Edit
                        </router-link>
                    </div>
                </div>

                <div class="bg-very-light py-4 px-4 px-sm-5 rounded">
                    <div class="row">
                        <div class="col-xl-2 col-md-3">
                            <p>Name:</p>
                        </div>

                        <div class="col-md-9">
                            <p>
                                {{ profile.first_name }} {{ profile.last_name }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-2 col-md-3">
                            <p>Email:</p>
                        </div>

                        <div class="col-md-9">
                            <p>{{ profile.mem_email }}</p>
                        </div>
                    </div>

                    <div v-if="!isSpecialNumber" class="row">
                        <div class="col-xl-2 col-md-3">
                            <p>Phone:</p>
                        </div>

                        <div class="col-md-9">
                            <p>
                                {{ profile.day_phone1 }}-{{
                                    profile.day_phone2
                                }}-{{ profile.day_phone3 }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-2 col-md-3">
                            <p>Address:</p>
                        </div>

                        <div class="col-md-9">
                            <p>
                                {{ profile.addr_1 }}<br />{{ profile.city }},
                                {{ profile.state }} {{ profile.zip }}
                            </p>
                        </div>
                    </div>

                    <div
                        v-if="
                            preferredFacility &&
                            preferredFacility.is_messaging_app_enabled
                        "
                        class="row mt-4 d-flex justify-content-center"
                    >
                        <button
                            type="button"
                            class="btn btn-lg btn-lg-rounded btn-primary"
                            @click="routeToIWebTouch"
                        >
                            Open iWebTouch
                        </button>
                    </div>
                </div>

                <div
                    id="schedule"
                    class="bg-very-light py-4 px-sm-5 px-3 mt-5 rounded"
                >
                    <div class="row mb-4">
                        <!-- TODO(Whole collection is being sent back when call profile routes.
                                 Return first() from those, and consume on frontend with 'profile') -->
                        <div v-if="preferredFacility" class="col-lg">
                            <h3>
                                Current Facility:
                                {{ preferredFacility.name }}
                            </h3>
                        </div>

                        <div class="col-lg-auto">
                            <a href="#" @click.prevent="toggleModal">{{
                                preferredFacility
                                    ? 'Choose a different Facility'
                                    : 'Select a Facility'
                            }}</a>
                        </div>

                        <div
                            v-if="isFacilityDeactivated"
                            class="col-12 text-secondary text-center mt-3"
                        >
                            <h3>{{ messages.deactivated }}</h3>
                        </div>

                        <div
                            v-if="isVisitorUnderage"
                            class="col-12 text-secondary text-center mt-3"
                        >
                            <h3>{{ messages.underage }}</h3>
                        </div>
                    </div>

                    <div v-if="deviceMessage" class="row">
                        <div
                            class="col-12 mt-lg-3 mb-lg-5 mb-4 mt-2 text-center"
                        >
                            <div ref="deviceMessage" class="py-2">
                                <h3
                                    class="border rounded p-2 important-message"
                                >
                                    {{ deviceMessage }}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div v-if="isRemoteEnabled" class="row">
                        <div
                            class="col-12 mt-lg-3 mb-lg-5 mb-4 mt-2 text-center"
                        >
                            <div ref="wifiMessage" class="py-2">
                                <h3
                                    class="border rounded p-2 important-message"
                                >
                                    For the best visitation experience, we
                                    recommend using a strong, stable Wi-Fi
                                    connection.
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="memberType && !memberType.is_default"
                        class="row"
                    >
                        <div
                            class="col-12 mt-lg-3 mb-lg-5 mb-4 mt-2 text-center"
                        >
                            <div class="py-2">
                                <h3 class="border rounded p-2 schedule-now">
                                    * Logged in as {{ memberType.name }} Visitor
                                </h3>
                            </div>
                        </div>
                    </div>

                    <template
                        v-if="
                            isFacilitiesLoaded &&
                            isProfileLoaded &&
                            preferredFacility
                        "
                    >
                        <div
                            v-if="isOnsiteEnabled || isRemoteEnabled"
                            class="row"
                        >
                            <div v-if="isRemoteEnabled" class="col-md">
                                <div class="text-center">
                                    <a
                                        :class="[
                                            buttonSizing,
                                            isDisabled,
                                            'btn btn-secondary mb-3 btn-lg-rounded',
                                        ]"
                                        @click="scheduleRemote"
                                    >
                                        Schedule a Remote Visit
                                    </a>
                                </div>

                                <ul>
                                    <li>
                                        Conduct a Remote visit from a location
                                        of your choice, at your convenience,
                                        using your computer or mobile phone.
                                    </li>
                                    <li>Save travel time.</li>
                                    <li>Save gas money.</li>
                                </ul>
                            </div>

                            <div v-if="isOnsiteEnabled" class="col-md">
                                <div class="text-center">
                                    <a
                                        :class="[
                                            buttonSizing,
                                            isDisabled,
                                            'btn btn-primary mb-3 btn-lg-rounded',
                                        ]"
                                        @click.prevent="scheduleOnsite"
                                    >
                                        Schedule an Onsite Visit
                                    </a>
                                </div>

                                <ul>
                                    <li>
                                        If you would like to travel to the
                                        facility you may schedule an Onsite
                                        Visit.
                                    </li>
                                    <li>
                                        You will be required to be present at
                                        the facility.
                                    </li>
                                    <li>
                                        You will not be able to use your own
                                        computer or mobile device.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div
                                class="col-12 mt-lg-3 mb-lg-5 mb-4 mt-2 text-center"
                            >
                                <div class="py-2">
                                    <h3
                                        class="border rounded p-2 important-message"
                                    >
                                        The {{ preferredFacility.name }} is
                                        currently offering onsite visitation
                                        only. All visits must be scheduled in
                                        advance by calling the facility
                                        directly.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div
                        v-else
                        class="bg-very-light py-4 px-sm-5 px-3 my-5 rounded text-center"
                    >
                        <div
                            class="spinner-border text-primary spinner-lg"
                            role="status"
                        ></div>
                    </div>
                </div>

                <div
                    v-if="isVisitsLoaded"
                    class="bg-very-light py-4 px-sm-5 px-3 my-5 rounded"
                >
                    <div
                        class="mb-4 d-flex justify-content-between align-items-center flex-wrap"
                    >
                        <div>
                            <h2 class="pt-2 pr-3 text-primary">
                                Visits
                                <template v-if="hasOnsiteVisits"
                                    ><span class="very-small font-italic">
                                        (onsite visits
                                        <span class="text-color-blue"
                                            >are blue</span
                                        ></span
                                    ><span class="very-small font-italic"
                                        >)</span
                                    >
                                </template>
                                <template v-if="hasVisitInvitations"
                                    ><span class="very-small font-italic">
                                        (visit invitations
                                        <span class="text-success"
                                            >are green</span
                                        ></span
                                    ><span class="very-small font-italic"
                                        >)</span
                                    >
                                </template>
                            </h2>
                        </div>

                        <div>
                            show
                            <select
                                v-model="visitFilter"
                                name="visits_filter"
                                class="custom-select w-auto"
                            >
                                <option value="all">all</option>
                                <option value="completed">completed</option>
                                <option value="pending" selected>
                                    scheduled
                                </option>
                                <option value="cancelled">cancelled</option>
                            </select>
                            visits
                        </div>
                    </div>

                    <VisitsTable
                        :filtered-visits="filteredVisits"
                        :filter="visitFilter"
                    />
                </div>
                <div
                    v-else
                    class="bg-very-light py-4 px-sm-5 px-3 my-5 rounded text-center"
                >
                    <div
                        class="spinner-border text-primary spinner-lg"
                        role="status"
                    ></div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="m-auto d-flex justify-content-center align-items-center"
        >
            <div
                class="spinner-border text-primary spinner-lg"
                role="status"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VisitsTable from '@/components/Account/VisitsTable';
import PreferredFacilityModal from '@/components/Account/PreferredFacilityModal';
import { notify } from '@/mixins/notify';
import { sizing } from '@/mixins/sizing';
import { messages } from '@/mixins/messages';
import { detector } from '@/mixins/detector';
import { animations } from '@/mixins/animations';
import { routing } from '@/mixins/routing';

export default {
    components: { VisitsTable, PreferredFacilityModal },
    mixins: [notify, sizing, detector, animations, routing, messages],
    beforeRouteLeave(to, from, next) {
        this.clearTimers();
        next();
    },
    props: {
        setFacility: {
            type: String,
            default: 'false',
        },
    },
    data() {
        return {
            visitFilter: 'pending',
            selectFacility: false,
        };
    },
    computed: {
        ...mapState('User', ['profile']),
        ...mapGetters('User', ['memberType']),
        ...mapState('Visits', ['visits']),
        ...mapState('VisitType', ['visitTypes']),
        ...mapState('Cart', ['cart']),
        ...mapGetters('Facility', [
            'preferredFacility',
            'isFacilitiesLoaded',
            'isFacilityDeactivated',
            'isOnsiteEnabled',
            'getOnsiteType',
            'totalPrerequisiteVisits',
        ]),
        ...mapGetters('User', ['isProfileLoaded', 'isUnderage']),
        ...mapGetters('Visits', ['isVisitsLoaded']),
        ...mapGetters('VisitType', ['areVisitTypesLoaded']),
        ...mapGetters('Cart', [
            'isCartLoaded',
            'totalCartVisits',
            'isDiscounted',
        ]),

        isSpecialNumber() {
            // This is a special case that we bypass entering the numbers.
            // We don't want to show this number to the visitors, though.
            return (
                !!this.profile &&
                !!this.profile.day_phone &&
                this.profile.day_phone === '777-555-3333'
            );
        },
        // Must have fac_minutes set at least, and if the facility only allows public defenders to schedule,
        // the visitor must be one. The member type must also be one of the confidential types.
        isRemoteEnabled() {
            return (
                this.isFacilitiesLoaded &&
                !!this.preferredFacility &&
                !!this.preferredFacility.remote_standard_length &&
                ((this.preferredFacility.is_public_defender_required &&
                    !!this.memberType &&
                    !this.memberType.is_visit_recorded &&
                    this.isProfileLoaded &&
                    this.profile.is_public_defender) ||
                    !this.preferredFacility.is_public_defender_required)
            );
        },
        isDisabled() {
            return !this.preferredFacility ||
                this.isFacilityDeactivated ||
                this.isVisitorUnderage
                ? 'disabled'
                : '';
        },
        filteredVisits() {
            return (
                this.hasVisits &&
                this.visits.filter((visit) =>
                    this.visitFilter === 'all'
                        ? true
                        : this.visitFilter === 'pending'
                        ? visit.status === 'pending' ||
                          visit.status === 'ongoing' ||
                          visit.status === 'upcoming'
                        : visit.status === this.visitFilter
                )
            );
        },
        isVisitorUnderage() {
            return (
                this.isProfileLoaded &&
                this.isFacilitiesLoaded &&
                this.preferredFacility &&
                this.isUnderage
            );
        },
        hasVisits() {
            return this.isVisitsLoaded && this.visits && this.visits.length > 0;
        },
        // Checks if the user has any onsite visits scheduled and updates view accordingly
        hasOnsiteVisits() {
            if (this.isVisitsLoaded && this.filteredVisits) {
                let visits = this.filteredVisits.filter(
                    (visit) => visit.is_onsite
                );

                return visits && visits.length > 0;
            } else {
                return false;
            }
        },
        // Same as above with onsites - checking if has any visit invites
        hasVisitInvitations() {
            if (this.isVisitsLoaded && this.filteredVisits) {
                let visits = this.filteredVisits.filter(
                    (visit) => this.profile.mem_id !== visit.visitor_id
                );

                return visits && visits.length > 0;
            } else {
                return false;
            }
        },
    },
    created() {
        this.reset();

        if (this.setFacility === 'true' && !this.preferredFacility) {
            this.selectFacility = true;
        }

        if (!this.areVisitTypesLoaded) {
            this.visitTypesRequest();
        }

        this.visitsRequest();

        // TODO(Use websockets instead?)
        // Will reload visits every minute, on the minute
        this.syncVisitsTimer().then(() => {
            this.visitsRequest({ is_reloading: true });

            let id = setInterval(() => {
                this.visitsRequest({ is_reloading: true });
            }, 60000);

            this.setIntervalId(id);
        });

        if (!this.isProfileLoaded) {
            this.userRequest();
        }

        this.cartRequest();
    },
    mounted() {
        // TODO(This next part with hash isn't working - at least not for desktop chrome. There is
        //       some bug with vue-router that has been going on for years)
        const hash = this.$route.hash;

        if (hash) {
            const el = document.getElementById(hash.substring(1, hash.length));

            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }

        // We're getting FANCY down here!
        if (this.deviceMessage) {
            this.onElementViewportEnter(
                this.$refs.deviceMessage,
                'shake text-secondary',
                0.6
            );
        }
        this.onElementViewportEnter(
            this.$refs.wifiMessage,
            'shake text-secondary',
            0.6
        );

        if (this.queryParams.has('goodbye')) {
            this.notify(
                'Thank you for using iWebVisit.com!',
                { duration: 3000 },
                true
            );
            const el = document.getElementById('schedule');

            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        this.clearTimers();
    },
    methods: {
        ...mapMutations('Schedule', ['reset', 'updateFacility']),
        ...mapMutations('VisitType', ['updateType']),
        ...mapMutations('Cart', ['updateIsAddingVisit']),
        ...mapActions('User', ['userRequest']),
        ...mapActions('Visits', [
            'visitsRequest',
            'setTimeoutId',
            'setIntervalId',
            'clearTimers',
        ]),
        ...mapActions('VisitType', ['visitTypesRequest']),
        ...mapActions('Cart', ['cartRequest', 'clearCartRequest']),
        ...mapActions('Schedule', ['inmatesRequest']),
        ...mapActions('Facility', ['facilityUpdateRequest']),

        toggleModal() {
            this.selectFacility = !this.selectFacility;
        },
        scheduleRemote() {
            this.updateType('remote');
            this.$toasted.clear();

            if (this.totalCartVisits) {
                this.handleCartRouting();
            } else {
                this.$router
                    .push({ name: 'schedule-inmate', hash: '#anchor' })
                    .catch(() => {});
            }
        },
        scheduleOnsite() {
            this.updateType(this.getOnsiteType);
            this.$router
                .push({ name: 'schedule-inmate', hash: '#anchor' })
                .catch(() => {});
        },
        syncVisitsTimer() {
            return new Promise((resolve) => {
                let id = setTimeout(() => {
                    resolve();
                }, (60 - new Date().getSeconds()) * 1000);

                this.setTimeoutId(id);
            });
        },
        // Precondition - Visits must be in cart!
        handleCartRouting() {
            let shouldKeepAddingToCart =
                this.totalCartVisits <= this.totalPrerequisiteVisits;

            let toast = {
                message: shouldKeepAddingToCart
                    ? 'You have promotional visits in your cart. Do you want to resume where you left off?'
                    : 'You have promotional visits in your cart. Do you want to check out now, or start over?',
                affirmativeText: shouldKeepAddingToCart ? 'Resume' : 'Checkout',
            };

            this.notify(
                toast.message,
                {
                    duration: null,
                    action: [
                        {
                            text: toast.affirmativeText,
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);

                                // Things go smoother if we setup a bit of the vuex data.
                                // We're going to use the first visit in the cart as setup.
                                const visit = this.cart[0];
                                this.inmatesRequest(visit).then(() =>
                                    this.facilityUpdateRequest(
                                        visit.facility.id
                                    ).then(() => {
                                        this.updateFacility(
                                            this.preferredFacility
                                        );

                                        if (shouldKeepAddingToCart) {
                                            this.updateIsAddingVisit(true);
                                            this.$router
                                                .push({
                                                    name: 'schedule-datetime',
                                                    hash: '#anchor',
                                                    params: {
                                                        hasCartVisits: true,
                                                    },
                                                })
                                                .then(() => {
                                                    if (this.isDiscounted) {
                                                        this.$swal.fire({
                                                            title: 'Discounted Visit!',
                                                            icon: 'success',
                                                            html: this.messages
                                                                .discountedVisit,
                                                            width: this
                                                                .modalSizing,
                                                        });
                                                    } else {
                                                        this.$swal.fire({
                                                            title: 'Almost there!',
                                                            icon: 'info',
                                                            html: this.messages
                                                                .additionalVisit,
                                                            width: this
                                                                .modalSizing,
                                                        });
                                                    }
                                                })
                                                .catch(() => {});
                                        } else {
                                            this.$router
                                                .push({
                                                    name: 'schedule-payment',
                                                    hash: '#anchor',
                                                    params: {
                                                        role: 'checkout',
                                                    },
                                                })
                                                .catch(() => {});
                                        }
                                    })
                                );
                            },
                        },
                        {
                            text: 'Start Over',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                                this.clearCartRequest();
                                this.$router
                                    .push({
                                        name: 'schedule-inmate',
                                        hash: '#anchor',
                                    })
                                    .catch(() => {});
                            },
                        },
                    ],
                },
                true
            );
        },
        routeToIWebTouch() {
            this.userRequest().then(() => {
                this.$router.push({
                    name: 'iwebvisit-touch.messaging.index',
                });
            });
        },
    },
};
</script>
