import { BREAKPOINT_SMALL, BREAKPOINT_MEDIUM } from '@/lib/helper';

export const sizing = {
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        screenWidth() {
            return this.windowWidth;
        },
        /** Large button or just normal-sized */
        buttonSizing() {
            return this.screenWidth < BREAKPOINT_SMALL ? '' : 'btn-lg';
        },
        actionButtonSizing() {
            return this.screenWidth < BREAKPOINT_MEDIUM ? '' : 'btn-lg';
        },
        iconSizing() {
            if (this.screenWidth >= BREAKPOINT_MEDIUM) {
                return 'fa-lg';
            } else if (this.screenWidth < BREAKPOINT_SMALL) {
                return 'fa-sm';
            } else {
                return '';
            }
        },
        modalSizing() {
            return this.screenWidth < BREAKPOINT_SMALL ? '32em' : '48em';
        },
        wideModalSizing() {
            return this.screenWidth < BREAKPOINT_SMALL ? '32em' : '60em';
        },
        visitTableButtons() {
            if (this.screenWidth >= BREAKPOINT_MEDIUM) {
                return '';
            } else if (
                this.screenWidth < BREAKPOINT_MEDIUM &&
                this.screenWidth >= BREAKPOINT_SMALL
            ) {
                return 'small';
            } else {
                // extra-small sized
                return 'btn-sm very-small';
            }
        },
    },
    created() {
        let resizer = () => (this.windowWidth = window.innerWidth);

        window.addEventListener('resize', resizer);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', resizer);
        });
    },
};
